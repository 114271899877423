import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DateRange } from "../../_interfaces/DateRange";
import { Fare } from "../../_models/Fare";

@Component({
  selector: 'app-fare-table-filters',
  templateUrl: './fare-table-filters.component.html',
  styleUrls: ['./fare-table-filters.component.scss']
})
export class FareTableFiltersComponent implements OnInit {

  public filter;

  public _date: DateRange;

  @Output() selectedDateChange = new EventEmitter<DateRange>();
  @Input() source: Fare[];
  @Output() destinationChange = new EventEmitter();
  @Output() originChange = new EventEmitter();
  @Output() isOnSaleChange = new EventEmitter<number>();
  @Output() priceValueChange = new EventEmitter();
  @Output() activeFilterChange = new EventEmitter();

  public filterTypes = [
    {
      id: 'date',
      text: 'Travel Date'
    },
    {
      id: 'price',
      text: 'Price'
    },
    {
      id: 'locations',
      text: 'Origin and Destination'
    },
    /*{
      id: 'sale',
      text: 'Fare type'
    }*/
  ];

  constructor() {
  }

  async ngOnInit() {
    this.filter = this.filterTypes[0];
  }

  get selectedDate() {
    return this._date;
  }

  @Input() set selectedDate(val) {
    this._date = val;
    this.selectedDateChange.emit(val)
  };

  public _priceValue;

  get priceValue() {
    return this._priceValue;
  };

  @Input() set priceValue(val) {
    this._priceValue = val;
    this.priceValueChange.emit(val);
  };

  public _destination;

  get destination() {
    return this._destination;
  }

  set destination(val) {
    this._destination = val;
    this.destinationChange.emit(val);
  }

  public _origin;

  set origin(val) {
    this._origin = val;
    this.originChange.emit(val);
  }

  public _isOnSale: number

  get isOnSale() {
    return this._isOnSale;
  }

  set isOnSale(val) {
    this._isOnSale = val;
    this.isOnSaleChange.emit(val);
  }

  onSelectedFilterChange(filter) {
    this.activeFilterChange.emit(filter);
    this.filter = filter;
  }

}
