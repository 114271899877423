import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DatePipe } from '@angular/common';
import 'daterangepicker';
import { DataStoreService } from '../_services/data-store.service';
import { LoadIndicatorService } from '../_services/load-indicator.service';
import { HorizontalTabsService } from '../_services/horizontal-tabs.service';
import {Tab} from '../_interfaces/tab';
import { FaresService } from '../_services/fares/fares.service';
import { UnixTimePropertyConverter } from '../_util/UnixTimePropertyConverter';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { FileSaverService } from 'ngx-filesaver';


interface SaleItem {
  advance_purchase: boolean;
  end_time: number;
  external_notes?: any;
  feed_only: boolean;
  firstlook_start?: any;
  happy_hour: boolean;
  id :number;
  percent_off: number;
  pos_region :string;
  sale_terms: any;
  start_time: number;
  tier_1: boolean;
  tier_2: boolean;
  title: string;
  url: string;
}


@Component({
  selector: 'app-specials-details',
  templateUrl: './specials-details.component.html',
  styleUrls: ['./specials-details.component.scss']
})

export class SpecialsDetailsComponent implements OnInit {
  public loadedData;
  public isLoading: boolean;
  public isEmpty: boolean = null;
  activeRoute: string;
  links: Tab[] = [];
  public unixTimePropertyConverter: UnixTimePropertyConverter = new UnixTimePropertyConverter();
  specialsurl = environment.specials_url;

  constructor(
              private faresService: FaresService,
              private htService: HorizontalTabsService,
              private dataStore: DataStoreService,
              private loadIndicatorService: LoadIndicatorService,
              private http: HttpClient,
              private fileSaver: FileSaverService
  ) {

  }

  ngOnInit() {
    this.activeRoute = '/private/my-fares/specials-details';
    this.loadIndicatorService.addLoading();
    this.isLoading = true;
    this.links = this.htService.specialsLinks;

    this.faresService.getSalesEvents().subscribe((data: any ) => {
      this.loadIndicatorService.removeLoading();

      if (data.sales.length) {
        this.loadedData = data.sales;
        const apiLoadedData = [];
        data.sales.forEach(function (item) {
          apiLoadedData.push(item.sale);
        });
        this.isLoading = false;
        this.loadedData = apiLoadedData;
        this.isEmpty = false;
      }
      else {
        this.isEmpty = true;
      }
    });
  }

  public convertTimeStamp(timestamp: number) {
    const dateTime =  this.unixTimePropertyConverter.mask(timestamp);
    return dateTime.toDateString();
  }

  public formatPercentOffString(percent: number) {
    let output = '';
    if (percent != 0) {
      output = 'Up to ' + percent + '% Off';
    }
    return output;
  }

  copyToClipboard(item: string, id: string) {
    const cb = (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (item));
      e.preventDefault();
      document.removeEventListener('copy', cb);
    };
    document.addEventListener('copy', cb);
    document.execCommand('copy');
    const idSelector = 'copy_text_' + id;
    const clickedTextEle = document.getElementById(idSelector);
    clickedTextEle.innerHTML = 'Copied';
    clickedTextEle.classList.add("copied_to_clip");
    setTimeout (() => {
      clickedTextEle.innerHTML = '';
      clickedTextEle.classList.remove("copied_to_clip");
    }, 1500);

  }

  onDownloadRawClick(id: string, title: string): void {
    const url = `https://${window.location.host}/backend/share_image/${id}/raw`;
    this.http.get(url, {
      observe: 'response',
      responseType: 'blob'
    }).subscribe(res => {
      this.fileSaver.save(res.body, `${title}.png`);
    });
  }

}
