import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { NgSelect2Module } from 'ng-select2';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';

import { ToastrModule } from 'ngx-toastr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { UserBadgeComponent } from './user-bage/user-badge.component';
import { NotFoundComponent } from './notFound.component';
import { ProfileComponent } from './profile/profile.component';
import { SpecialsComponent } from './specials/specials.component';
import { MatchedFaresComponent } from './alerts/matched-fares/matched-fares.component';
import { MyAlertsComponent } from './alerts/my-alerts/my-alerts.component';
import { HistoryComponent } from './alerts/history/history.component';
import { MyFaresUserMenuComponent } from './my-fares-user-menu/my-fares-user-menu.component';
import { NewAlertComponent } from './alerts/new-alert/new-alert.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { JsonApiModule } from 'angular2-jsonapi';
import { HorizontalTabsComponent } from './common/horizontal-tabs/horizontal-tabs.component';
import { RegisterComponent } from './register/register.component';
import { TwoColComponent } from './common/private/two-col/two-col.component';
import { SingleColComponent } from './common/public/single-col/single-col.component';
import { DatePipe } from '@angular/common';
import { ErrorHandlerIntercept } from './http-error-handler.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LogoutComponent } from './logout/logout.component';
import { TermsAndConditionsComponent } from './register/terms-and-conditions/terms-and-conditions.component';
import { ForgotPasswordComponent } from './user/forgot-password/forgot-password.component';
import { WaitingForApprovalComponent } from './waiting-for-approval/waiting-for-approval.component';
import { PasswordResetConfirmComponent } from './user/password-reset-confirm/password-reset-confirm.component';
import { ChangePasswordComponent } from './user/change-password/change-password.component';
import { DateRangePickerFixes } from './_util/DateRangePickerFixes';
import { LoaderAnimationComponent } from './common/loader-animation/loader-animation.component';
import { ResetEmailSentComponent } from './user/reset-email-sent/reset-email-sent.component';
import { Content } from './_models/Content';
import { ContentService } from './_services/content.service';
import { LoadIndicatorService } from './_services/load-indicator.service';
import { FareTableComponent } from './fare-table/fare-table.component';
import { FareTableTitleComponent } from './fare-table/render-components/fare-table-title/fare-table-title.component';
import { FilterSwitcherComponent } from './fare-table/render-components/filter-switcher/filter-switcher.component';
import { FareTableDateFilterComponent } from './fare-table/filter-components/fare-table-date-filter/fare-table-date-filter.component';
import { FareTablePriceFilterComponent } from './fare-table/filter-components/fare-table-price-filter/fare-table-price-filter.component';
// tslint:disable-next-line:max-line-length
import { FareTableLocationFilterComponent } from './fare-table/filter-components/fare-table-location-filter/fare-table-location-filter.component';
//import { FareTableSaleFilterComponent } from './fare-table/filter-components/fare-table-sale-filter/fare-table-sale-filter.component';
import { Ng5SliderModule } from 'ng5-slider';
import { FareTableOnSaleSoonComponent } from './fare-table/render-components/fare-table-on-sale-soon/fare-table-on-sale-soon.component';
import { ContactComponent } from './contact/contact.component';
import { VaaNoticeComponent } from './common/vaa-notice/vaa-notice.component';
import { UiSwitchModule } from 'ngx-toggle-switch';
import { ShareFareDialogComponent } from './dialogs/share-fare-dialog/share-fare-dialog.component';
import { FareTableShareComponent } from './fare-table/render-components/fare-table-share/fare-table-share.component';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { NavigationEnd, Router } from "@angular/router";
import { VaaNoticesComponent } from './common/vaa-notices/vaa-notices.component';
import { VaaNoticesService } from "./_services/VaaNoticesService";
import { DebugComponent } from './debug/debug.component';
import { FareTableFiltersComponent } from './fare-table/fare-table-filters/fare-table-filters.component';
import { FareTableCombinedComponent } from './fare-table/fare-table-combined/fare-table-combined.component';
import { HowtoComponent } from './howto/howto.component';
import { TermsComponent } from './terms/terms.component';
import {SpecialsDetailsComponent} from "./specials-details/specials-details.component";
@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        UserBadgeComponent,
        NotFoundComponent,
        ProfileComponent,
        SpecialsComponent,
        SpecialsDetailsComponent,
        MatchedFaresComponent,
        MyAlertsComponent,
        HistoryComponent,
        MyFaresUserMenuComponent,
        NewAlertComponent,
        HorizontalTabsComponent,
        RegisterComponent,
        TwoColComponent,
        SingleColComponent,
        LogoutComponent,
        TermsAndConditionsComponent,
        ForgotPasswordComponent,
        WaitingForApprovalComponent,
        PasswordResetConfirmComponent,
        ChangePasswordComponent,
        ResetEmailSentComponent,
        LoaderAnimationComponent,
        FareTableComponent,
        FareTableTitleComponent,
        FareTableShareComponent,
        FilterSwitcherComponent,
        FareTableDateFilterComponent,
        FareTablePriceFilterComponent,
        FareTableLocationFilterComponent,
        //FareTableSaleFilterComponent,
        FareTableOnSaleSoonComponent,
        ContactComponent,
        VaaNoticeComponent,
        ShareFareDialogComponent,
        VaaNoticesComponent,
        VaaNoticeComponent,
        DebugComponent,
        FareTableFiltersComponent,
        FareTableCombinedComponent,
        HowtoComponent,
        TermsComponent,
    ],
  entryComponents: [
    NewAlertComponent,
    FareTableTitleComponent,
    FilterSwitcherComponent,
    FareTableOnSaleSoonComponent,
    ShareFareDialogComponent,
    FareTableShareComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AutocompleteLibModule,
    JsonApiModule,
    Ng2SmartTableModule,
    NgSelect2Module,
    MatDialogModule,
    Ng5SliderModule,
    UiSwitchModule,
    DeviceDetectorModule.forRoot(),
    MatCheckboxModule,
    MatRadioModule,
    ToastrModule.forRoot()
  ],
  providers: [
    DateRangePickerFixes,
    DatePipe,
    {provide: MatDialogRef, useValue: {}},
    { provide: HTTP_INTERCEPTORS, useClass: ErrorHandlerIntercept, multi: true},
    LoadIndicatorService,
    VaaNoticesService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    private contentService: ContentService,
    private router: Router,
    public vaaNoticesService: VaaNoticesService,
    ) {
    const preload = ['home_title', 'home_description', 'terms_and_conditions', 'login_page'];
    preload.forEach((val) => {
      contentService.getContent(val).subscribe((content: Content) => {

      });
    });
    // dataService.findAll(Content).subscribe((contents: JsonApiQueryData<Content>) => {
    //   console.log('%c Pre-loaded all Content', 'color: green');
    // });
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.vaaNoticesService.clear();
      }
    });
  }
}
